import { useEffect, useState } from 'react';

function useScreenWakeLock() {
  const [screenLock, setScreenLock] = useState(null);

  // Function to check if the Screen Wake Lock API is supported in the browser
  function isScreenLockSupported() {
    return 'wakeLock' in navigator;
  }

  //Function to request the screen wake lock
  async function getScreenLock() {
    console.log("isScreenLockSupported",isScreenLockSupported());
    if (isScreenLockSupported()) {
      try {
        const lock = await navigator.wakeLock.request('screen');
        setScreenLock(lock);
        console.log('Screen wake lock acquired');
      } catch (err) {
        console.log("Error catch block");
        console.log(err);
        console.log(err.name, err.message);
      }
    }
  }

  // Function to release the screen wake lock
  function releaseScreenLock() {
    if (screenLock) {
      screenLock.release().then(() => {
        console.log('Screen wake lock released');
        setScreenLock(null);
      });
    }
  }

  // Use useEffect to automatically acquire the lock when the component mounts
  useEffect(() => {
    getScreenLock();

    // Release the lock when the component unmounts
    return () => {
      releaseScreenLock();
    };
  }, []);

  // Handle visibility change to re-acquire the lock when the tab becomes visible
  useEffect(() => {
    function handleVisibilityChange() {
      if (screenLock && document.visibilityState === 'visible') {
        getScreenLock();
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [screenLock]);

  return screenLock;
}

export default useScreenWakeLock;
