function useScreenOrientationLock() {
  const lockOrientation = () => {
    const screenOrientation = window.screen.orientation;

    if (screenOrientation && screenOrientation.lock) {
      screenOrientation
        .lock("portrait")
        .then(() => {
          console.log("Screen orientation locked to portrait");
        })
        .catch((error) => {
          console.error("Error locking screen orientation:", error);
        });
    } else {
      console.warn("Screen orientation API or lock method is not available in this browser");
    }
  };

  const unlockOrientation = () => {
    const screenOrientation = window.screen.orientation;

    if (screenOrientation && screenOrientation.unlock) {
      screenOrientation
        .unlock()
        .then(() => {
          console.log("Screen orientation unlocked");
        })
        .catch((error) => {
          console.error("Error unlocking screen orientation:", error);
        });
    } else {
      console.warn("Screen orientation API or unlock method is not available in this browser");
    }
  };

  // Return the lock and unlock functions
  return { lockOrientation, unlockOrientation };
}

export default useScreenOrientationLock;
