export const webRTCInitialState = {
  currentWebRTCScreen: "",
  callInviterCopy: null,
  hideBottomBtn: false,
  showMediaAccessModal: false,
  showHangUpModal: false,
};

export const webRTCReducer = (state, action) => {
  switch (action.type) {
    case "setCurrentWebRTCScreen":
      return {
        ...state,
        currentWebRTCScreen: action.payload,
      };
    case "setCallInviterCopy":
      return {
        ...state,
        callInviterCopy: action.payload,
      };
    case "setHideBottomBtn":
      return {
        ...state,
        hideBottomBtn: action.payload,
      };
    case "setShowMediaAccessModal":
      return {
        ...state,
        showMediaAccessModal: action.payload,
      };
    case "setShowHangUpModal":
      return {
        ...state,
        showHangUpModal: action.payload,
      };
    default:
      return state;
  }
};
