import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./HavingTouble.css";
import { getcontinueRecord } from "../services/supervisiorGuide";
const HavingTouble = (props) => {
  const { state } = props;

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const irn = searchParams.get("irn");

  const lang = searchParams.get("lang") ? searchParams.get("lang") : "English";

  const projectUUID = searchParams.get("projectUUID");

  const participantUUID = (searchParams.get("participantUUID") !== '' && searchParams.get("participantUUID") !== null) ? searchParams.get("participantUUID") : searchParams.get("k");

  const handelNavigate = () => {
    if (participantUUID) {
      navigate(
        `/havingtroublepage?participantUUID=${participantUUID}&lang=${lang}`,
        {
          state: state,
        }
      );
    } else if (projectUUID && irn) {
      navigate(
        `/havingtroublepage?projectUUID=${projectUUID}&irn=${irn}&lang=${lang}`,
        {
          state: state,
        }
      );
    } else if (projectUUID) {
      navigate(`/havingtroublepage?projectUUID=${projectUUID}&lang=${lang}`, {
        state: state,
      });
    } else {
      navigate(`/`);
    }
    trackRecord();
  };

  const trackRecord = () => {
    getcontinueRecord(
      state.email,
      "Access",
      state.accessData.help_link_text,
      state.ParticipantUUID,
      state.session_id
    )
      .then((res) => {
        // console.log( "response", res );
      })
      .catch((err) => {});
  };
  return (
    <>
      <span className="having-touble-text" onClick={handelNavigate}>
        {state.accessData.help_link_text}
      </span>
    </>
  );
};

export default HavingTouble;
