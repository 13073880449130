import React, { useReducer } from "react";
import { webRTCInitialState, webRTCReducer } from "../reducers/WebRTCReducers";

const WebRTCContext = React.createContext();

const WebRTCProvider = (props) => {
  const [state, dispatch] = useReducer(webRTCReducer, webRTCInitialState);
  const { parentProps } = props;
  
  return (
    <WebRTCContext.Provider
      value={{
        webRTCState: state,
        webRTCDispatch: dispatch,
        parentProps: parentProps,
      }}
    >
      {props.children}
    </WebRTCContext.Provider>
  );
};

export { WebRTCContext, WebRTCProvider };
