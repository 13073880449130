import React, { useState, useEffect } from "react";
import moment from "moment";
import "./thankyou.css";
import { useLocation, useNavigate } from "react-router-dom";
const ThankYou = () => {
  const navigate = useNavigate();

  const location = useLocation();

  let selectedData = location.state;

  const [confirmationPageDetail, setConfirmationPageDetail] = useState(null);

  const [loading, setloading] = useState(false);

  useEffect(() => {
    if (selectedData.length > 0) {
      setConfirmationPageDetail(selectedData[0].select_item);
      setloading(true);
    } else {
      navigate("/");
    }
  }, []);

  return (
    <>
      {loading && (
        <div className="thankyou-container">
          <div className="thankyou-inner-container">
            <header className="thankyou-header-container">
              {moment().format("DD MMMM YYYY")}
            </header>
            <main className="thankyou-main-container">
              <p>{confirmationPageDetail.ht_paragrapth_text_1}</p>
            </main>
            <footer className="thankyou-footer-container">
              <p className="thank-you-text">
                {confirmationPageDetail.ht_thank_you_titile}
              </p>
              <p>{confirmationPageDetail.ht_footer_thank_you_party}</p>
              <p className="close-window">
                {confirmationPageDetail.ht_window_close_msg}
              </p>
            </footer>
          </div>
        </div>
      )}
    </>
  );
};

export default ThankYou;
