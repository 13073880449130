import React from 'react';
import { Modal } from "react-bootstrap";

export default function HavingTroubleModal({ handleOpenModal, handleCloseModal, captcha, updateUserAnswer, userAnswer, errorMessage, validateCaptcha }) {
    return (<>
        <Modal show={handleOpenModal} onHide={handleCloseModal} size={"md"}>
            <Modal.Header closeButton className='having_modal_header'>
                <Modal.Title>Please solve the math problem below and press “Submit” to send your request:</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={validateCaptcha}>
                    <div className='having_body_form_wrapper'>
                    <div className="having_body_form_captcha">{captcha}</div>
                    <div className="input-group mb-3 catcha_input_group" >
                        <input
                            type="number"
                            value={userAnswer}
                            onChange={(e) => updateUserAnswer(e.target.value)}
                            className="form-control catch_input_f"
                            placeholder="Your Answer"
                            min="0"
                            max="18"
                            required
                        />
                        <button type="submit" className="btn btn-primary catcha_submit_btn">
                            Submit
                        </button>
                    </div>
                    </div>
                    {errorMessage && <p className="error-message">{errorMessage}</p>}
                    
                </form>
            </Modal.Body>

        </Modal>
    </>
    )
}
