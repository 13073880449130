import { ErrorMessage, Field, Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import * as Yup from "yup";
import {
  getcontinueRecord,
  verifyRecaptchaToken,
} from "../services/supervisiorGuide";
import {
  getProjectDetailsWithIRN,
  getSupervisorProjectData,
  getSupervisorParticipantData,
  guideSelfhelp,
  getParticipantDetails,
} from "../services/supervisiorGuide";
import "./HavingTroublePage.css";
import TextError from "./TextError";
import HavingTroubleModal from "./HavingTroubleModal";

const HavingTroublePage = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const projectUUID = searchParams.get("projectUUID");

  const irn = searchParams.get("irn");

  const participantUUID = searchParams.get("participantUUID");

  const language = searchParams.get("lang");

  const session_id = location.state?.session_id;

  const navigate = useNavigate();

  const [displaydata, setDisplaydata] = useState([]);

  const [options, setOptions] = useState([]);

  const [loading, setloading] = useState(false);

  const [otherText, setOtherText] = useState("");

  const [projectDetail, setProjectDetail] = useState("");

  const [thankyouPageContent, setThankyouPageContent] = useState("");
  const [captcha, setCaptcha] = useState('');
  const [userAnswer, setUserAnswer] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const lang = language ? language : "English";

    if (projectUUID && irn) {
      getSupervisorProjectData(projectUUID, 0, lang)
        .then((res) => {
          const selectedData = res.data[0].values.filter(
            (row) => row.page_type === "Self Help Page"
          );
          const confirmationPageDetail = res.data[0].values.filter(
            (row) => row.page_type === "Self Help Confirmation Page"
          );
          setThankyouPageContent(confirmationPageDetail);
          perpareIssueList(selectedData);
        })
        .catch((err) => {
          navigate("/");
        });
      getProjectDetailsWithIRN(projectUUID, irn, session_id, lang)
        .then((res) => {
          setProjectDetail(res.data);
        })
        .catch((err) => {
          navigate("/");
        });
    } else if (projectUUID) {
      getSupervisorProjectData(projectUUID, 0, lang)
        .then((res) => {
          const selectedData = res.data[0].values.filter(
            (row) => row.page_type === "Self Help Page"
          );
          const confirmationPageDetail = res.data[0].values.filter(
            (row) => row.page_type === "Self Help Confirmation Page"
          );
          setThankyouPageContent(confirmationPageDetail);
          perpareIssueList(selectedData);
        })
        .catch((err) => {
          navigate("/");
        });
    } else if (participantUUID) {
      getSupervisorParticipantData(participantUUID, 0, lang)
        .then((res) => {
          const selectedData = res.data[0].values.filter(
            (row) => row.page_type === "Self Help Page"
          );
          const confirmationPageDetail = res.data[0].values.filter(
            (row) => row.page_type === "Self Help Confirmation Page"
          );
          setThankyouPageContent(confirmationPageDetail);
          perpareIssueList(selectedData);
        })
        .catch(() => {
          navigate("/");
        });
      getParticipantDetails(participantUUID, session_id)
        .then((res) => {
          setProjectDetail(res.data);
        })
        .catch(() => {
          navigate("/");
        });
    } else {
      navigate("/");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_SITE_KEY}`,
      function () {
      }
    );
  }, []);

  const perpareIssueList = (selectedData) => {
    let optionList = [];

    if (selectedData.length > 0) {
      let selectItem = { ...selectedData[0].select_item };

      let size = Object.keys(selectItem).length;
      for (let index = 1; index < size; index++) {
        let optionListObj = { value: index, key: index };

        Object.keys(selectItem).forEach(function (key) {
          if (key === `ht_option_text_${index}`) {
            optionListObj["ht_option_text"] = selectItem[key];
          }
          if (key === `ht_option_text_${index}_sub_text`) {
            optionListObj["ht_option_text_sub_text"] = selectItem[key];
          }
        });
        if (optionListObj.ht_option_text) {
          optionList.push(optionListObj);
        }
      }
      setOptions(optionList);
      setDisplaydata(selectItem);
      setloading(true);
    }
  };

  const onSubmit = (values, submitProp) => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.REACT_APP_SITE_KEY, {
          action: "submit",
        })
        .then((token) => {
          verifyToken(token, submitProp);
        });
    });
  };

  const verifyToken = (token, submitProp) => {
    const recaptchaThreshold = process.env.REACT_APP_RECAPTCHA_THRESHOLD
    verifyRecaptchaToken(token)
      .then((res) => {
        if (res.data.score > recaptchaThreshold) {
          handleSubmit(submitProp);
        } else {
          handleShowModal();
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const handleSubmit = (values, submitProps) => {
    let uuid = participantUUID
      ? participantUUID
      : location.state.ParticipantUUID;
    guideSelfhelp(uuid, otherText)
      .then(() => {
        navigate("/thankyou", { state: thankyouPageContent });
        submitProps.setSubmitting(false);
        submitProps.resetForm();
      })
      .catch(() => {
        submitProps.setSubmitting(false);
      });
    if (session_id) {
      trackRecord();
    }
  };

  const trackRecord = () => {
    getcontinueRecord(
      location.state?.email,
      "self help page",
      displaydata.ht_button_text,
      location.state.ParticipantUUID,
      session_id
    )
      .then((res) => {})
      .catch((err) => {});
  };

  const validationSchema = Yup.object({
    otherText: Yup.string().required("Please fill the details"),
  });

  const initialValues = {
    otherText: "",
  };
  const handleTextAreaValue = (e, setFieldValue) => {
    setFieldValue("otherText", e.target.value);
    setOtherText(e.target.value);
  };

  const generateCaptcha = () => {
    const num1 = Math.floor(Math.random() * 10);
    const num2 = Math.floor(Math.random() * 10);
    const sum = num1 + num2;

    setCaptcha(`${num1} + ${num2}`);
    return sum;
  };

  const handleShowModal = () => {
    generateCaptcha();
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setUserAnswer('');
    setShowModal(false);
  };

  const validateCaptcha = (event) => {
    event.preventDefault();

    const captchaSum = eval(captcha);

    if (parseInt(userAnswer) === captchaSum) {
      handleCloseModal();
      setErrorMessage('');
      handleSubmit(event);
    } else {
      generateCaptcha();
      setErrorMessage('Incorrect answer. Please try again.');
      setTimeout(clearErrorMessage, 1500);
    }
  };

  const clearErrorMessage = () => {
    setErrorMessage('');
  };

  return (
    <>
      {loading && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ values, setFieldValue, isSubmitting }) => {
            return (
              <div className="having-trouble-container">
                <Form>
                  <header className="having-trouble-header-container">
                    {moment().format("DD MMMM YYYY")}
                  </header>
                  <main className="having-trouble-main-container">
                    <div className="main-container-text">
                      <p className="user-name">
                        {displaydata.ht_greeting_text}{" "}
                        <span>{projectDetail.name},</span>
                      </p>
                      <p>{displaydata.ht_paragraph_1_text}</p>
                    </div>
                    <Field name="selectedOption">
                      {({ field }) => {
                        return (
                          <div className="option-container">
                            <ul>
                              {options.map((option) => {
                                return (
                                  <li key={option.key}>
                                    <div className="option-header">
                                      {option.ht_option_text && (
                                        <span className="heading">
                                          {option.ht_option_text}
                                        </span>
                                      )}
                                    </div>
                                    <div className="option-text">
                                      {option.ht_option_text_sub_text && (
                                        <span>
                                          {option.ht_option_text_sub_text}
                                        </span>
                                      )}
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                            <ul>
                              <li>
                                <label className="other-option-container">
                                  <span className="heading">
                                    {displaydata.ht_option_other_text}
                                  </span>
                                  <div className="textarea-container">
                                    <textarea
                                      value={otherText}
                                      rows="3"
                                      onChange={(e) =>
                                        handleTextAreaValue(e, setFieldValue)
                                      }
                                      className="other-text-container"
                                      placeholder={
                                        displaydata.ht_option_other_placeholder_text
                                      }
                                    />
                                    <ErrorMessage
                                      name="otherText"
                                      component={TextError}
                                    />
                                  </div>
                                </label>
                              </li>
                            </ul>
                          </div>
                        );
                      }}
                    </Field>
                  </main>
                  <footer className="having-trouble-footer-container">
                    <div className="having-trouble-footer-container-text">
                      {displaydata.ht_footer_text}
                    </div>
                    <button type="submit" disabled={isSubmitting}>
                      {displaydata.ht_button_text}
                    </button>
                  </footer>
                </Form>
              </div>
            );
          }}
        </Formik>
      )}
      {
        showModal && (<>
          <HavingTroubleModal 
            userAnswer={userAnswer} 
            captcha={captcha} 
            handleCloseModal={handleCloseModal} 
            updateUserAnswer={setUserAnswer} 
            handleOpenModal={showModal} 
            errorMessage={errorMessage} 
            validateCaptcha={validateCaptcha} 
          />
        </>)
      }
    </>
  );
};

export default HavingTroublePage;
