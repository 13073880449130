import { faCheck, faPhoneSquareAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { Component } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import ReactPlayer from "react-player";
import { v4 as uuidv4 } from "uuid";
import "./App.css";
import { BASE_URL } from "./config";
import {
  getParticipantDetails,
  getProjectDetails,
  getSupervisorParticipantData,
  getSupervisorProjectData,
  getbackRecord,
  getclickDialRecord,
  getcontinueRecord,
  getProjectDetailsWithIRN,
} from "./services/supervisiorGuide";
import MainWebRTC from "./WebRtc/pages/MainWebRTC";
import { WebRTCProvider } from "./WebRtc/context/WebRTCContext";
import HavingTouble from "./HavingTrouble";
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      supervisorData: [],
      welcomeSection: true,
      questionnaireSection: false,
      interview_completedSection: false,
      videoSection: false,
      instructionSection: false,
      instructionSectionHeadphone: false,
      instructionSectionSpeak: false,
      instructionSectionCongo: false,
      confirmQuestionnaire: false,
      callingInstruction: false,
      Thankyou: false,
      displayData: [],
      splashData: "",
      welcomeData: "",
      interview_completedData: "",
      videoData: "",
      instruction1Data: "",
      instruction2Data: "",
      instruction3Data: "",
      readyData: "",
      readyConfirmData: "",
      accessData: "",
      finishData: "",
      ProjectDetail: "",
      ParticipantUUID: "",
      projectUUID: "",
      ProjectparticipantDetail: "",
      email: "",
      loader: true,
      error: "",
      apiError: "",
      backVideoSection: false,
      backInstructionSection: false,
      backinstructionSectionHeadphone: false,
      errormsg: false,
      disablecontinue_videobtn: true,
      link_expired: false,
      showVideo: false,
      isParticipantAllowedFlag: true,
      isParticipantAllowedMessage: "",
      isVideoSectionflag: true,
      callDelayBy: 3,
      session_id: "",
      webRTCPageContent: [],
      isConnectionStable: true,
      pageTypesWithEnabledFlag: [],
    };
  }

  handelBack = (id, pagename) => {
    const stateChanges = {
      1: {
        questionnaireSection: true,
      },
      3: {
        videoSection: true,
        showVideo: true,
        backVideoSection: true,
      },
      4: {
        backInstructionSection: true,
        instructionSection: true,
      },
      5: {
        instructionSectionHeadphone: true,
      },
      6: {
        instructionSectionSpeak: true,
      },
      7: {
        instructionSectionCongo: true,
      },
      8: {
        confirmQuestionnaire: true,
      },
    };

    const newState = {
      welcomeSection: false,
      questionnaireSection: false,
      videoSection: false,
      showVideo: false,
      backVideoSection: false,
      backInstructionSection: false,
      instructionSection: false,
      instructionSectionHeadphone: false,
      backinstructionSectionHeadphone: false,
      instructionSectionSpeak: false,
      instructionSectionCongo: false,
      confirmQuestionnaire: false,
      callingInstruction: false,
      Thankyou: false,
    };

    if (stateChanges[id]) {
      Object.assign(newState, stateChanges[id]);
      this.backRecord(pagename);
    }

    this.setState(newState);
  };
  getQueryParam = (name, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  };

  componentDidMount() {
    const ProjectUUID = this.getQueryParam("projectUUID");
    let ParticipantUUID = this.getQueryParam("participantUUID");
    const expiry_date = this.getQueryParam("exp");
    const key = this.getQueryParam("k");
    const irn = this.getQueryParam("irn");
    const session_id = uuidv4();
    const lang =
      this.getQueryParam("lang") !== null
        ? this.getQueryParam("lang")
        : "English";
    console.log("ProjectUUID", ProjectUUID);
    console.log("ParticipantUUID", ParticipantUUID);
    console.log("expiry_date", expiry_date);
    console.log("irn", irn);
    console.log("lang", lang);
    if (key) {
      ParticipantUUID = key;
    }
    if (ProjectUUID === null && ParticipantUUID === null) {
      this.setState({
        errormsg: true,
        loader: false,
        session_id: uuidv4(),
      });
    } else {
      if (ProjectUUID !== "" && ProjectUUID !== null && irn != null) {
        getSupervisorProjectData(ProjectUUID, expiry_date, lang)
          .then((res) => {
            // this.setState(
            //   {
            //     supervisorData: res.data,
            //     loader: false,
            //   },
            //   () => {
            //     this.perpareData();
            //   }
            // );
            const showdisplayData = res.data;
            const data = showdisplayData[0].values.filter(
              (type) => type.page_type === "Error Message"
            );
            console.log("values", res.data[0]?.values);
            this.setState(
              {
                isParticipantAllowedMessage:
                  data.length !== 0
                    ? data[0].select_item.Participant_not_allowed
                    : "",
                supervisorData: res.data,
                welcomeSection: false,
                pageTypesWithEnabledFlag: this.getPageTypesWithEnabledFlag(
                  res.data[0]?.values
                ),
                questionnaireSection: true,
              },
              () => {
                this.perpareData();
              }
            );
            setTimeout(() => {
              this.setState({
                loader: false,
              });
            }, 1000);
          })
          .catch((err) => {
            if (
              err.response?.data.message !==
              "Something went wrong! Unable to process Request."
            ) {
              this.setState({
                errormsg: true,
                loader: false,
              });
            }
          });
        getProjectDetailsWithIRN(ProjectUUID, irn, session_id, lang)
          .then((res) => {
            this.setState({
              ProjectDetail: res.data,
              email: res.data.email,
              ParticipantUUID: res.data.participantUUID,
              isVideoSectionflag: res.data?.isVideoSection,
              welcomeSection: false,
              questionnaireSection: true,
              loader: false,
              session_id: session_id,
              callDelayBy: res.data?.callDelayBy,
            });
          })
          .catch((err) => {
            console.log("error cath", err);
            if (err.response?.status === 417) {
              this.setState({
                json_blank: false,
                interview_completedSection: true,
                loader: false,
                apiError: err.response?.data.message,
              });
            } else {
              this.setState({
                loader: false,
                interview_completedSection: true,
                apiError: err.response?.data.message,
              });
            }
          });
      } else if (ProjectUUID !== "" && ProjectUUID !== null) {
        this.setState({
          projectUUID: ProjectUUID,
          session_id: uuidv4(),
        });
        getSupervisorProjectData(ProjectUUID, expiry_date, lang)
          .then((res) => {
            this.setState(
              {
                supervisorData: res.data,
                loader: false,
              },
              () => {
                this.perpareData();
              }
            );
          })
          .catch((err) => {
            this.setState({
              errormsg: true,
              loader: false,
            });
          });
      }
      // JSON for Content Load
      if (ParticipantUUID !== "" && ParticipantUUID !== null) {
        this.setState(
          {
            ParticipantUUID: ParticipantUUID,
            session_id: uuidv4(),
          },
          () => {
            this.handelparticipant();
          }
        );
        getSupervisorParticipantData(ParticipantUUID, expiry_date, lang)
          .then((res) => {
            const showdisplayData = res.data;
            const data = showdisplayData[0].values.filter(
              (type) => type.page_type === "Error Message"
            );
            console.log("values", res.data[0]?.values);
            this.setState(
              {
                isParticipantAllowedMessage:
                  data.length !== 0
                    ? data[0].select_item.Participant_not_allowed
                    : "",
                supervisorData: res.data,
                welcomeSection: false,
                pageTypesWithEnabledFlag: this.getPageTypesWithEnabledFlag(
                  res.data[0]?.values
                ),
                questionnaireSection: true,
              },
              () => {
                this.perpareData();
              }
            );
            setTimeout(() => {
              this.setState({
                loader: false,
              });
            }, 1000);
          })
          .catch((err) => {
            this.setState({
              errormsg: false,
              loader: false,
            });
          });
      }
    }
  }

  handelparticipant = () => {
    getParticipantDetails(this.state.ParticipantUUID)
      .then((res) => {
        this.setState({
          ProjectparticipantDetail: res.data,
          ParticipantUUID: res.data.participantUUID,
          email: res.data.email,
          projectUUID: res.data.projectUUID,
          isVideoSectionflag: res.data?.isVideoSection,
          isParticipantAllowedFlag:
            res.data.isParticipantAllowed === "true" ? true : false,
          callDelayBy: res.data?.callDelayBy,
        });
      })
      .catch((err) => {
        console.log("error cath", err);
        if (err.response?.status === 417) {
          this.setState({
            interview_completedSection: true,
            loader: false,
            apiError: err.response?.data?.message,
          });
        } else {
          console.log("error cath 400", err);
          this.setState({
            loader: false,
            apiError: err.response?.data?.message,
          });
        }
      });
  };

  handelproject = (values) => {
    getProjectDetails(this.state.projectUUID, values)
      .then((res) => {
        this.setState({
          ProjectDetail: res.data,
          email: res.data.email,
          ParticipantUUID: res.data.participantUUID,
          isVideoSectionflag: res.data?.isVideoSection,
          welcomeSection: false,
          questionnaireSection: true,
        });
      })
      .catch((err) => {
        console.log("error cath", err);
        if (err.response?.status === 417) {
          this.setState({
            interview_completedSection: true,
            loader: false,
            apiError: err.response?.data?.message,
          });
        } else {
          this.setState({
            loader: false,
            apiError: err.response?.data?.message,
          });
        }
      });
  };

  continueRecord = (page_name) => {
    getcontinueRecord(
      this.state.email,
      page_name,
      "continue",
      this.state.ParticipantUUID,
      this.state.session_id
    )
      .then((res) => {})
      .catch((err) => {});
  };

  backRecord = (page_name) => {
    getbackRecord(
      this.state.email,
      page_name,
      "back",
      this.state.ParticipantUUID,
      this.state.session_id
    )
      .then((res) => {})
      .catch((err) => {});
  };

  clickDialRecord = (page_name) => {
    getclickDialRecord(
      this.state.email,
      page_name,
      "click_to_dial",
      this.state.ParticipantUUID,
      this.state.session_id
    )
      .then((res) => {})
      .catch((err) => {});
  };

  notEmail = () => {
    this.setState({
      welcomeSection: true,
      questionnaireSection: false,
    });
  };

  handelContinue = (id, pagename) => {
    console.log("id", id);
    console.log("page", pagename);
    const stateChanges = {
      1: {
        questionnaireSection: true,
      },
      2: {
        videoSection: true,
        showVideo: true,
      },
      3: {
        instructionSection: true,
      },
      4: {
        instructionSectionHeadphone: true,
      },
      5: {
        instructionSectionSpeak: true,
      },
      6: {
        instructionSectionCongo: true,
      },
      7: {
        confirmQuestionnaire: true,
      },
      8: {
        callingInstruction: true,
      },
      9: {
        Thankyou: true,
      },
    };

    const newState = {
      welcomeSection: false,
      questionnaireSection: false,
      videoSection: false,
      showVideo: false,
      instructionSection: false,
      instructionSectionHeadphone: false,
      instructionSectionSpeak: false,
      instructionSectionCongo: false,
      confirmQuestionnaire: false,
      callingInstruction: false,
      Thankyou: false,
    };

    if (stateChanges[id]) {
      Object.assign(newState, stateChanges[id]);
      this.continueRecord(pagename);
    }
    this.setState(newState);
  };

  perpareData = () => {
    const showdisplayData = this.state.supervisorData;
    this.setState(
      {
        displayData: showdisplayData[0].values,
      },
      () => {
        this.splashData();
        this.welcomeData();
        this.videoData();
        this.instruction1Data();
        this.instruction2Data();
        this.instruction3Data();
        this.readyData();
        this.readyConfirmData();
        this.accessData();
        this.setWebRTCData();
        this.finishData();
        this.interview_completed();
      }
    );
  };

  splashData = () => {
    const data = this.state.displayData.filter(
      (type) => type.page_type === "Splash"
    );
    if (data.length > 0) {
      this.setState({
        splashData: data[0].select_item,
      });
    }
  };
  welcomeData = () => {
    const data = this.state.displayData.filter(
      (type) => type.page_type === "Welcome"
    );
    if (data.length > 0) {
      this.setState({
        welcomeData: data[0].select_item,
      });
    }
  };
  interview_completed = () => {
    const data = this.state.displayData.filter(
      (type) => type.page_type === "Interview Completed"
    );
    if (data.length > 0) {
      this.setState({
        interview_completedData: data[0].select_item,
      });
    }
  };
  videoData = () => {
    const data = this.state.displayData.filter(
      (type) => type.page_type === "Video"
    );
    if (data.length > 0) {
      this.setState({
        videoData: data[0].select_item,
      });
    }
  };
  instruction1Data = () => {
    const data = this.state.displayData.filter(
      (type) => type.page_type === "Instruction 1"
    );
    if (data.length > 0) {
      this.setState({
        instruction1Data: data[0].select_item,
      });
    }
  };
  instruction2Data = () => {
    const data = this.state.displayData.filter(
      (type) => type.page_type === "Instruction 2"
    );
    if (data.length > 0) {
      this.setState({
        instruction2Data: data[0].select_item,
      });
    }
  };
  instruction3Data = () => {
    const data = this.state.displayData.filter(
      (type) => type.page_type === "Instruction 3"
    );
    if (data.length > 0) {
      this.setState({
        instruction3Data: data[0].select_item,
      });
    }
  };
  readyData = () => {
    const data = this.state.displayData.filter(
      (type) => type.page_type === "Ready"
    );
    if (data.length > 0) {
      this.setState({
        readyData: data[0].select_item,
      });
    }
  };
  readyConfirmData = () => {
    const data = this.state.displayData.filter(
      (type) => type.page_type === "Ready Confirm"
    );
    if (data.length > 0) {
      this.setState({
        readyConfirmData: data[0].select_item,
      });
    }
  };
  accessData = () => {
    const data = this.state.displayData.filter(
      (type) => type.page_type === "Access"
    );
    if (data.length > 0) {
      this.setState({
        accessData: data[0].select_item,
      });
    }
  };

  setWebRTCData = () => {
    const webRTCPageContent = this.state.displayData.filter(
      (type) => type.page_type === "WebRTC Page"
    );
    if (webRTCPageContent.length > 0) {
      this.setState({
        webRTCPageContent: webRTCPageContent[0].select_item,
      });
    }
  };
  finishData = () => {
    const data = this.state.displayData.filter(
      (type) => type.page_type === "Finish"
    );
    if (data.length > 0) {
      this.setState({
        finishData: data[0].select_item,
      });
    }
  };

  handleEnded = () => {
    this.setState({ disablecontinue_videobtn: false });
  };

  handleDuration = (duration) => {
    console.log("onDuration", duration);
  };

  handleCallDelayBy = () => {
    let countComma = "";
    if (this.state.callDelayBy) {
      for (let count = 1; count <= this.state.callDelayBy; count++) {
        countComma += ",";
      }
    }
    return countComma;
  };

  hideWebRTCScreen = () => {
    this.setState({ isConnectionStable: false });
  };

  findPreviousEnabledPageNumber(pages, pageNumber) {
    if (pages.length > 0) {
      for (let i = pageNumber - 1; i >= 2; i--) {
        if (pages[i].page_enabled) {
          return pages[i].page_number;
        }
      }
    }
    return 1; // No more enabled pages found within the range
  }

  findNextEnabledPageNumber(pages, pageNumber) {
    console.log("pages----", pages);
    console.log("pag n---", pageNumber);
    if (pageNumber === 9) {
      return 9;
    }
    if (pages.length > 0) {
      const startIndex = pageNumber; // Adjusting for 1-based index
      for (let i = startIndex; i < pages.length; i++) {
        if (pages[i].page_enabled) {
          return pages[i].page_number - 1;
        }
      }
      return pageNumber;
    } // No more enabled pages found, start from the beginning
  }

  getPageTypesWithEnabledFlag = (pages) => {
    let pageNumber = 1;

    return pages
      .filter((page) => {
        const excludedPageTypes = [
          "Self Help Page",
          "Self Help Confirmation Page",
          "Finish",
        ];
        return !excludedPageTypes.includes(page.page_type);
      })
      .map((page) => {
        let page_number;

        if (page.page_type === "Splash") {
          page_number = pageNumber++;
        } else if (page.page_type === "WebRTC Page") {
          page_number = 9;
        } else {
          page_number = pageNumber++;
        }

        return {
          page_type: page.page_type,
          page_enabled: page.page_enabled === "true",
          page_number: page_number,
        };
      });
  };

  handleDialInBackBtn = () => {
    const isWebRtcEnable = this.state.ProjectparticipantDetail?.isWebrtcEnable;
    if (isWebRtcEnable === "true") {
      this.setState({ isConnectionStable: true });
    } else {
      this.handelBack(
        this.findPreviousEnabledPageNumber(
          this.state.pageTypesWithEnabledFlag,
          8
        ),
        "Access"
      );
    }
  };

  render() {
    const emailTest = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const isWebRtcEnable = this.state.ProjectparticipantDetail?.isWebrtcEnable;
    const irnTest = /^[0-9\b]+$/;
    const parentProps = {
      parentState: this.state,
      hideWebRTCScreen: this.hideWebRTCScreen,
      handelContinue: this.handelContinue,
      handelBack: this.handelBack,
      findPreviousEnabledPageNumber: this.findPreviousEnabledPageNumber,
      findNextEnabledPageNumber: this.findNextEnabledPageNumber,
      pageTypesWithEnabledFlag: this.state.pageTypesWithEnabledFlag,
    };

    return (
      <div className="App">
        {this.state.loader ? (
          <div className="loader-div">
            <div className="loader">
              <p>Please be patient we are loading your data...</p>
            </div>
          </div>
        ) : this.state.displayData.length > 0 ? (
          <div className="container-fluid p-0">
            {/* Welcome-section Start */}
            {!this.state.isParticipantAllowedFlag ? (
              <div className="d-flex justify-content-center align-items-center h-100 is_participant_allowed_message_class">
                <label className="error-msg red_color">
                  {this.state.isParticipantAllowedMessage}
                </label>
              </div>
            ) : (
              <>
                <section
                  className={`sectionClass welcome-section ${
                    this.state.welcomeSection ? "Show" : ""
                  }`}
                >
                  <Container>
                    {this.state.splashData !== "" ? (
                      <Row>
                        <Col>
                          <h1>{this.state.splashData.header_text}</h1>
                          <p>{this.state.splashData.main_text}</p>
                          <div className="Form-section">
                            <Formik
                              validate={(values) => {
                                let errors = {};
                                if (values.email === "") {
                                  errors.email = "Email is required";
                                } else if (!emailTest.test(values.email)) {
                                  errors.email =
                                    "Please enter valid email address";
                                }
                                if (values.irn === "") {
                                  errors.irn = "IRN is required";
                                } else if (!irnTest.test(values.irn)) {
                                  errors.irn = "Please enter number only";
                                }
                                return errors;
                              }}
                              initialValues={{ email: "", irn: "" }}
                              onSubmit={(values, { setSubmitting }) => {
                                this.handelproject(values);
                                setSubmitting(false);
                              }}
                            >
                              {({ touched, errors, isSubmitting }) => (
                                <Form>
                                  <div className="form-group">
                                    <label htmlFor="email">
                                      {this.state.splashData.field_label1_text}
                                    </label>
                                    <Field
                                      type="email"
                                      name="email"
                                      className={`form-control ${
                                        touched.email && errors.email
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                    />
                                    <ErrorMessage
                                      component="div"
                                      name="email"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="irn">
                                      {this.state.splashData.field_label2_text}
                                    </label>
                                    <Field
                                      type="text"
                                      name="irn"
                                      className={`form-control ${
                                        touched.irn && errors.irn
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                    />
                                    <ErrorMessage
                                      component="div"
                                      name="irn"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                  {this.state.error != "" ? (
                                    <label className="error-msg">
                                      {this.state.splashData.error_message}
                                    </label>
                                  ) : (
                                    <p className="error-msg">
                                      {this.state.error}
                                    </p>
                                  )}

                                  <div className="button-sec">
                                    <Button
                                      variant="primary"
                                      type="submit"
                                      disabled={isSubmitting}
                                    >
                                      {isSubmitting
                                        ? "Please wait..."
                                        : this.state.splashData
                                            .button_advance_text}
                                    </Button>
                                  </div>
                                </Form>
                              )}
                            </Formik>
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                  </Container>
                </section>
                {/* Welcome-section End */}

                {/* Welcome-Questionnaire section Start */}
                <section
                  className={`sectionClass welcome-section Questionnaire ${
                    this.state.questionnaireSection ? "Show" : ""
                  }`}
                >
                  <Container>
                    {this.state.welcomeData !== "" ? (
                      <Row>
                        <Col>
                          <p className="sub-head">
                            {this.state.welcomeData.subheader_text}
                          </p>
                          <p className="qus-text">
                            {this.state.welcomeData.main_text}{" "}
                          </p>
                          {this.state.ProjectparticipantDetail.email === "" ||
                          this.state.ProjectDetail.email === "" ? (
                            ""
                          ) : (
                            <span
                              className="not-email"
                              onClick={() => this.notEmail()}
                            >
                              {this.state.welcomeData.email_confirm_text !== ""
                                ? this.state.ProjectparticipantDetail.email
                                  ? this.state.welcomeData.email_confirm_text.split(
                                      "["
                                    )[0] +
                                    "[" +
                                    this.state.ProjectparticipantDetail.email +
                                    "] ? "
                                  : this.state.ProjectDetail.email
                                  ? this.state.welcomeData.email_confirm_text.split(
                                      "["
                                    )[0] +
                                    "[" +
                                    this.state.ProjectDetail.email +
                                    "] ? "
                                  : this.state.welcomeData.email_confirm_text
                                : ""}
                            </span>
                          )}

                          <div className="button-sec">
                            <Button
                              variant="primary"
                              type="button"
                              onClick={() =>
                                this.handelContinue(
                                  this.findNextEnabledPageNumber(
                                    this.state.pageTypesWithEnabledFlag,
                                    2
                                  ),
                                  "Welcome"
                                )
                              }
                            >
                              {this.state.welcomeData.button_advance_text}
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                  </Container>
                </section>
                {/* Welcome-Questionnaire section Start */}

                {/* Video section Start backVideoSection */}
                <section
                  className={`sectionClass video-section ${
                    this.state.videoSection ? "Show" : ""
                  }  ${this.state.backVideoSection ? "backShow" : ""}`}
                >
                  <Container fluid>
                    {this.state.videoData !== "" ? (
                      <>
                        <Row>
                          <Col lg={5} md={12} className="vid-txt">
                            <p className="qus-text sub-head">
                              {this.state.videoData.main_text}
                            </p>
                          </Col>
                          <Col lg={7} md={12} className="vid-icon">
                            {/* <iframe src={this.state.videoData.image_link} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe> */}
                            {/* <iframe src="https://player.vimeo.com/video/480038691" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullscreen></iframe> */}
                            {/* <img src={this.state.videoData.image_link} alt="youtubelogo" className="img-fluid" /> */}
                            <div className="videodiv">
                              {this.state.showVideo ? (
                                <ReactPlayer
                                  url={this.state.videoData.image_link}
                                  onEnded={this.handleEnded}
                                  onError={(e) => console.log("onError", e)}
                                  onDuration={this.handleDuration}
                                  playsinline={true}
                                  controls={true}
                                />
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <div className="full-widthbutton-sec">
                          <Button
                            variant="primary"
                            type="button"
                            disabled={this.state.disablecontinue_videobtn}
                            onClick={() =>
                              this.handelContinue(
                                this.findNextEnabledPageNumber(
                                  this.state.pageTypesWithEnabledFlag,
                                  3
                                ),
                                "Video"
                              )
                            }
                          >
                            {this.state.videoData.button_advance_text}
                          </Button>
                        </div>
                      </>
                    ) : null}
                  </Container>
                </section>
                {/* Video section End */}

                {/* instruction section Start */}
                <section
                  className={`sectionClass instruction-section quiet ${
                    this.state.instructionSection ? "Show" : ""
                  } ${this.state.backInstructionSection ? "backShow" : ""}`}
                >
                  <Container fluid>
                    {this.state.instruction1Data !== "" ? (
                      <>
                        <Row>
                          <Col lg={5} md={12}>
                            <img
                              src={this.state.instruction1Data.image_link}
                              alt="volume"
                              className="img-fluid"
                            />
                            <p className="qus-text sub-head">
                              {this.state.instruction1Data.image_footer_text}
                            </p>
                          </Col>
                          <Col lg={7} md={12}>
                            <p>{this.state.instruction1Data.main_text}</p>
                          </Col>
                        </Row>
                        <div className="full-widthbutton-sec">
                          <Button
                            variant="primary"
                            type="submit"
                            className="mr-4"
                            onClick={() =>
                              this.handelBack(
                                this.findPreviousEnabledPageNumber(
                                  this.state.pageTypesWithEnabledFlag,
                                  3
                                ),
                                "Instruction 1"
                              )
                            }
                          >
                            {this.state.instruction1Data.button_reverse_text}
                          </Button>
                          <Button
                            variant="primary"
                            type="submit"
                            onClick={() =>
                              this.handelContinue(
                                this.findNextEnabledPageNumber(
                                  this.state.pageTypesWithEnabledFlag,
                                  4
                                ),
                                "Instruction 1"
                              )
                            }
                          >
                            {this.state.instruction1Data.button_advance_text}
                          </Button>
                        </div>
                      </>
                    ) : null}
                  </Container>
                </section>
                {/* instruction section End */}

                {/* instruction Headphone section Start backinstructionSectionHeadphone */}
                <section
                  className={`sectionClass instruction-section instructionSectionHeadphone ${
                    this.state.instructionSectionHeadphone ? "Show" : ""
                  } ${
                    this.state.backinstructionSectionHeadphone ? "backShow" : ""
                  }`}
                >
                  <Container fluid>
                    {this.state.instruction2Data !== "" ? (
                      <>
                        <Row>
                          <Col lg={5} md={12}>
                            <img
                              src={this.state.instruction2Data.image_link}
                              alt="volume"
                              className="img-fluid"
                            />
                            <p className="qus-text sub-head">
                              {this.state.instruction2Data.image_footer_text}
                            </p>
                          </Col>
                          <Col lg={7} md={12} className="heaphone-col">
                            <p>{this.state.instruction2Data.main_text}</p>
                          </Col>
                        </Row>
                        <div className="full-widthbutton-sec">
                          <Button
                            variant="primary"
                            type="submit"
                            className="mr-4"
                            onClick={() =>
                              this.handelBack(
                                this.findPreviousEnabledPageNumber(
                                  this.state.pageTypesWithEnabledFlag,
                                  4
                                ),
                                "Instruction 2"
                              )
                            }
                          >
                            {this.state.instruction2Data.button_reverse_text}
                          </Button>
                          <Button
                            variant="primary"
                            type="button"
                            onClick={() =>
                              this.handelContinue(
                                this.findNextEnabledPageNumber(
                                  this.state.pageTypesWithEnabledFlag,
                                  5
                                ),
                                "Instruction 2"
                              )
                            }
                          >
                            {this.state.instruction2Data.button_advance_text}
                          </Button>
                        </div>
                      </>
                    ) : null}
                  </Container>
                </section>
                {/* instruction Headphone section End */}

                {/* instruction Speakup section Start */}
                <section
                  className={`sectionClass instruction-section instructionSectionSpeak ${
                    this.state.instructionSectionSpeak ? "Show" : ""
                  }`}
                >
                  <Container fluid>
                    {this.state.instruction3Data !== "" ? (
                      <>
                        <Row>
                          <Col lg={5} md={12}>
                            <img
                              src={this.state.instruction3Data.image_link}
                              alt="volume"
                              className="img-fluid"
                            />
                            <p className="qus-text sub-head">
                              {this.state.instruction3Data.image_footer_text}
                            </p>
                          </Col>
                          <Col lg={7} md={12} className="heaphone-col">
                            <p>{this.state.instruction3Data.main_text}</p>
                          </Col>
                        </Row>
                        <div className="full-widthbutton-sec">
                          <Button
                            variant="primary"
                            type="submit"
                            className="mr-4"
                            onClick={() =>
                              this.handelBack(
                                this.findPreviousEnabledPageNumber(
                                  this.state.pageTypesWithEnabledFlag,
                                  5
                                ),
                                "Instruction 3"
                              )
                            }
                          >
                            {this.state.instruction3Data.button_reverse_text}
                          </Button>
                          <Button
                            variant="primary"
                            type="button"
                            onClick={() =>
                              this.handelContinue(
                                this.findNextEnabledPageNumber(
                                  this.state.pageTypesWithEnabledFlag,
                                  6
                                ),
                                "Instruction 3"
                              )
                            }
                          >
                            {this.state.instruction3Data.button_advance_text}
                          </Button>
                        </div>
                      </>
                    ) : null}
                  </Container>
                </section>
                {/* instruction Speakup section End */}

                {/* instruction Congo section Start */}
                <section
                  className={`sectionClass instruction-section instructionSectionCongo ${
                    this.state.instructionSectionCongo ? "Show" : ""
                  }`}
                >
                  <Container fluid>
                    {this.state.readyData !== "" ? (
                      <>
                        <Row>
                          <Col lg={5} md={12}>
                            <img
                              src={this.state.readyData.image_link}
                              alt="volume"
                              className="img-fluid"
                            />
                            <p className="qus-text sub-head">
                              {this.state.readyData.image_footer_text}
                            </p>
                          </Col>
                          <Col lg={7} md={12} className="heaphone-col">
                            <p>{this.state.readyData.main_text}</p>
                            <p>{this.state.readyData.main2_text}</p>
                          </Col>
                        </Row>
                        <div className="full-widthbutton-sec">
                          <Button
                            variant="primary"
                            type="submit"
                            className="mr-4"
                            onClick={() =>
                              this.handelBack(
                                this.findPreviousEnabledPageNumber(
                                  this.state.pageTypesWithEnabledFlag,
                                  6
                                ),
                                "Ready"
                              )
                            }
                          >
                            {this.state.readyData.button_reverse_text}
                          </Button>
                          <Button
                            variant="primary"
                            type="button"
                            onClick={() =>
                              this.handelContinue(
                                this.findNextEnabledPageNumber(
                                  this.state.pageTypesWithEnabledFlag,
                                  7
                                ),
                                "Ready"
                              )
                            }
                          >
                            {this.state.readyData.button_advance_text}
                          </Button>
                        </div>
                      </>
                    ) : null}
                  </Container>
                </section>
                {/* instruction Congo section End */}

                {/* confirmquestionnaire section Start */}
                <section
                  className={`sectionClass confirmquestionnaire-section ${
                    this.state.confirmQuestionnaire ? "Show" : ""
                  }`}
                >
                  <Container fluid>
                    {this.state.readyConfirmData !== "" ? (
                      <>
                        <Row>
                          <Col lg={6} md={12}>
                            <ul>
                              <li>
                                <FontAwesomeIcon icon={faCheck} />{" "}
                                {this.state.readyConfirmData.confirm1_text}
                              </li>
                              <li>
                                <FontAwesomeIcon icon={faCheck} />{" "}
                                {this.state.readyConfirmData.confirm2_text}
                              </li>
                              <li>
                                <FontAwesomeIcon icon={faCheck} />{" "}
                                {this.state.readyConfirmData.confirm3_text}
                              </li>
                            </ul>
                            <p className="qus-text">
                              {this.state.readyConfirmData.image_footer_text}
                            </p>
                          </Col>
                          <Col lg={6} md={12} className="heaphone-col">
                            <p>{this.state.readyConfirmData.main_text}</p>
                          </Col>
                        </Row>
                        <div className="full-widthbutton-sec">
                          <Button
                            variant="primary"
                            type="submit"
                            className="mr-4"
                            onClick={() =>
                              this.handelBack(
                                this.findPreviousEnabledPageNumber(
                                  this.state.pageTypesWithEnabledFlag,
                                  7
                                ),
                                "Ready Confirm"
                              )
                            }
                          >
                            {this.state.readyConfirmData.button_reverse_text}
                          </Button>
                          <Button
                            variant="primary"
                            type="button"
                            onClick={() =>
                              this.handelContinue(
                                this.findNextEnabledPageNumber(
                                  this.state.pageTypesWithEnabledFlag,
                                  8
                                ),
                                "Ready Confirm"
                              )
                            }
                          >
                            {this.state.readyConfirmData.button_advance_text}
                          </Button>
                        </div>
                      </>
                    ) : null}
                  </Container>
                </section>
                {/* confirmquestionnaire section Start */}

                {/* callinginstruction section Start */}
                <section
                  className={`sectionClass callinginstruction-section ${
                    this.state.callingInstruction ? "Show" : ""
                  }`}
                >
                  <Container fluid>
                    {this.state.accessData !== "" ? (
                      <>
                        {this.state.ProjectparticipantDetail &&
                        JSON.parse(
                          this.state.ProjectparticipantDetail.isWebrtcEnable
                        ) &&
                        this.state.isConnectionStable ? (
                          <WebRTCProvider parentProps={parentProps}>
                            <MainWebRTC />
                          </WebRTCProvider>
                        ) : (
                          <>
                            <Row>
                              <Col md={12} className="">
                                <p className="head-text">
                                  {this.state.accessData.header}
                                </p>
                                <p>{this.state.accessData.field_label1_text}</p>
                                <p>
                                  {this.state.ProjectDetail.numberToCall
                                    ? this.state.ProjectDetail.numberToCall
                                    : this.state.ProjectparticipantDetail
                                        .numberToCall
                                    ? this.state.ProjectparticipantDetail
                                        .numberToCall
                                    : "-"}{" "}
                                </p>
                                <div className="access-code">
                                  <p>
                                    {this.state.accessData.field_label2_text}
                                  </p>
                                  <p>
                                    {this.state.ProjectDetail.accessCode
                                      ? this.state.ProjectDetail.accessCode
                                      : this.state.ProjectparticipantDetail
                                          .accessCode
                                      ? this.state.ProjectparticipantDetail
                                          .accessCode
                                      : "-"}{" "}
                                  </p>
                                  <HavingTouble state={this.state} />
                                  {/* <a
                                    className="having-trouble"
                                    href={`${BASE_URL}participant-service/public/candidate/support?participant=${this.state.ParticipantUUID}`}
                                    target="_blank"
                                  >
                                    {this.state.accessData.help_link_text}
                                  </a> */}
                                </div>
                                <a
                                  href={`tel:${
                                    this.state.ProjectDetail.numberToCall
                                      ? this.state.ProjectDetail.numberToCall
                                          .replace(/[(-]/g, "")
                                          .replace(/[)]/g, "")
                                      : this.state.ProjectparticipantDetail
                                          .numberToCall
                                      ? this.state.ProjectparticipantDetail.numberToCall
                                          .replace(/[(-]/g, "")
                                          .replace(/[)]/g, "")
                                      : "-"
                                  }${this.handleCallDelayBy()}${
                                    this.state.ProjectparticipantDetail
                                      .accessCode !== undefined
                                      ? this.state.ProjectparticipantDetail.accessCode.replace(
                                          /[-]/g,
                                          ""
                                        )
                                      : ""
                                  }#`}
                                  className="click_dial btn-primary btn"
                                  onClick={() => this.clickDialRecord("Access")}
                                >
                                  {this.state.accessData.clicktodial_image_text}
                                  <FontAwesomeIcon
                                    icon={faPhoneSquareAlt}
                                    className="dial-icon"
                                  />
                                </a>
                              </Col>
                            </Row>
                            <div className="full-widthbutton-sec">
                              <Button
                                variant="primary"
                                type="submit"
                                className={`mr-4 ${
                                  isWebRtcEnable === "true"
                                    ? "visible-button"
                                    : "hidden-button"
                                } dial_back`}
                                onClick={this.handleDialInBackBtn}
                              >
                                {this.state.accessData.button_reverse_text}
                              </Button>
                            </div>
                          </>
                        )}

                        {/*
                          adding webRTC logic to show the call button
                        */}
                        {}
                      </>
                    ) : null}
                  </Container>
                </section>
                {/* instruction Congo section Start */}

                {/* Thank you section Start */}
                <section
                  className={`sectionClass Thankyou-section ${
                    this.state.Thankyou ? "Show" : ""
                  }`}
                >
                  <Container fluid>
                    {this.state.finishData !== "" ? (
                      <>
                        <Row>
                          <Col md={12} className="">
                            <p>{this.state.finishData.header}</p>
                            <div className="Thankyou-text">
                              <p>{this.state.finishData.text}</p>
                            </div>
                          </Col>
                        </Row>
                        <div className="full-widthbutton-sec"></div>
                      </>
                    ) : null}
                  </Container>
                </section>
                {/* Thank you section end */}

                {/* Interview completed section start */}
                <section
                  className={`sectionClass welcome-section Questionnaire interview_completed ${
                    this.state.interview_completedSection ? "Show" : ""
                  }`}
                >
                  <Container>
                    {this.state.interview_completedData !== "" ? (
                      <Row>
                        <Col>
                          <p className="sub-head">
                            {this.state.interview_completedData.header}
                          </p>
                          <p className="qus-text">
                            {this.state.interview_completedData.text}{" "}
                          </p>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                  </Container>
                </section>
              </>
            )}
          </div>
        ) : (
          ""
        )}

        {this.state.apiError ? (
          <div className="loader-div error-msg">
            <p>{this.state.apiError}</p>
          </div>
        ) : (
          ""
        )}
        {this.state.errormsg ? (
          <div className="loader-div error-msg">
            <p>
              Invalid interview URL. Make sure that you have the correct URL.
            </p>
          </div>
        ) : (
          ""
        )}
        {this.state.link_expired ? (
          <div className="loader-div error-msg">
            <p>Link you requested is expired. Please contact support team.</p>
          </div>
        ) : (
          ""
        )}
      </div>
    );
    // })
  }
}

export default App;
