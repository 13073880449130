import Bowser from "bowser";
export const WebRTCLogsMsg = {
  checking_network_speed: "Checking network speed",
  user_created_successfully: "User agent is created successfully",
  user_created_failed: "User agent creation fail",
  user_register_successfully: "User register successfully",
  user_creation_fail: "User registration fail",
  grant_media_access: "User grant media access",
  denied_media_access: "User Denied media access",
  invitation_sent_successfully: "Invitation sent successfully",
  Invitation_failed: "Invitation did not send",
  connection_failed:
    "Network speed is not fast for good quality interview.please use detail below to make  the  direct call",
  call_terminate_successfully: "Call terminate successfully",
};

export const getBrowserDetails = () => {
  const browser = Bowser.getParser(window.navigator.userAgent);
  return `${browser.getBrowserName()}/${browser.getBrowserVersion()}`
}

export const getOSDetails = () => {
  const browser = Bowser.getParser(window.navigator.userAgent);
  return `${browser.getOSName()}/${browser.getOSVersion()}`
}

export function isMobileDevice() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

