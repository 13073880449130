import axios from 'axios';
const baseURL = 'https://sip-us-04.stg.clearspeed.us:8485'
export function receiveSoundTestCredentials() {
    return new Promise(function (resolve, reject) {
        try {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const urlData = urlParams.get('id')

            axios({
                method: 'post',
                baseURL: baseURL,
                url: '/sound_test_credentials',
                responseType: 'json',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                data: urlData,
            }).then(function (response) {
                if (response.status === 200) {
                    resolve(JSON.stringify(response.data));
                } else {
                    reject();
                }
            }).catch(function (error) {
                console.error(error);
                reject(error);
            });
        } catch (error) {
            reject(error);
        }
    });
}

export async function checkNetworkSpeed(defaultSpeed, isSpeedTestEnabled, speedTestBaseUrl) {
    
  const fileURL = speedTestBaseUrl + "/speedtest";
  
  if (isSpeedTestEnabled === "true") {
    try {
      const startTime = new Date().getTime();
      const response = await fetch(fileURL);
      const endTime = new Date().getTime();

      if (response.ok) {
        const downloadSize = (await response.text()).length;
        const time = (endTime - startTime) / 1000;
        const sizeInBits = downloadSize * 8;
        const speed = (sizeInBits / time / (1024 * 1024)).toFixed(2);
        return speed;
      } else {
        throw new Error("Network request failed");
      }
    } catch (error) {
      throw new Error("Network error");
    }
  } else {
    return defaultSpeed;
  }
}

export function uploadVideo(videoBlob) {
    return new Promise(function (resolve, reject) {
        try {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const urlData = urlParams.get('id')

            axios({
                method: 'post',
                baseURL: baseURL,
                url: '/get_interview_id',
                responseType: 'json',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                data: urlData,
            }).then(function (response) {
                if (response.status === 200) {
                    const interviewId = response.data;

                    const formData = new FormData();
                    formData.append('video', videoBlob, `${interviewId}.webm`);

                    axios({
                        method: 'post',
                        baseURL: baseURL,
                        url: `/upload_video?interview_id=${interviewId}?uuid=${urlData}`,
                        responseType: 'json',
                        mode: 'no-cors',
                        headers: {
                            'Content-Type': 'multipart/form-data;',
                        },
                        data: formData,
                    }).then(function (response) {
                        resolve();
                    }).catch(function (error) {
                        console.error(error);
                        reject(error);
                    });
                } else {
                    reject();
                }
            }).catch(function (error) {
                console.error(error);
                reject(error);
            });
        } catch (error) {
            reject(error);
        }
    });
}
