import axios from "axios";
import { BASE_URL, BASE_SERVICE_URI } from "./config";

const api = axios.create({
  baseURL: BASE_URL + BASE_SERVICE_URI,
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    let errorObj = Object.assign({}, error);
    if (
      Object.keys(errorObj).length === 0 ||
      errorObj.response.status === 401
    ) {
      //axios.isCancel()
      return Promise.reject(errorObj);
    }
    if (errorObj.response.status === 500) {
      errorObj = {
        response: {
          data: {
            message: "Something went Wrorng! Unable to process Request. ",
          },
        },
      };
      return Promise.reject(errorObj);
    }
    return Promise.reject(errorObj);
  }
);

api.interceptors.request.use(function (config) {
  config.headers.common["Content-Type"] = "application/json";
  return config;
});

export default api;
