import React from "react";
import App from "./App";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import HavingTroublePage from "./HavingTrouble/HavingTroublePage";
import ThankYou from "./HavingTrouble/ThankYou";

const AppEntry = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <App />,
    },
    {
      path: "/havingtroublepage",
      element: <HavingTroublePage />,
    },
    {
      path: "/thankyou",
      element: <ThankYou />,
    },
  ]);
  return <RouterProvider router={router} />;
};

export default AppEntry;