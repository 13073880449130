import React, { useContext, useEffect, useState } from "react";
import { WebRTCContext } from "../context/WebRTCContext";
import { Row, Col, Button } from "react-bootstrap";
import { Inviter, Registerer, SessionState, UserAgent } from "sip.js";
import bluePhoneIcon from "../../assets/images/blue-phone.png";
import greenPhoneIcon from "../../assets/images/green-phone.png";
import inCallIcon from "../../assets/images/inCall_image.png";
import redPhoneIcon from "../../assets/images/red-phone.png";
import { trackWebRTCRecords, getWebRTCProps,} from "../../services/supervisiorGuide";
import {
  WebRTCLogsMsg,
  isMobileDevice,
  getBrowserDetails,
  getOSDetails,
} from "../util";
import { checkNetworkSpeed } from "../../services/api";
import CustomModal from "../components/CustomModal";
import "../style/customModal.css";
import "../style/rtc.css";
import useScreenWakeLock from "../components/useScreenWakeLock";
import useFullScreen from "../components/useFullScreen";

const MainWebRTC = () => {
  const { webRTCState, webRTCDispatch, parentProps } =
    useContext(WebRTCContext);

  const [isMediaAllow, setIsMediaAllow] = useState(false);

  const{ enterFullScreen, exitFullScreen }=useFullScreen();

  const {
    hideWebRTCScreen,
    handelContinue,
    parentState,
    handelBack,
    findPreviousEnabledPageNumber,
    pageTypesWithEnabledFlag,
  } = parentProps;

  const { webRTCPageContent } = parentState;

  const screenLock = useScreenWakeLock();

  const {
    hideBottomBtn,
    callInviterCopy,
    showMediaAccessModal,
    showHangUpModal,
  } = webRTCState;

  useEffect(() => {
    if (isMediaAllow) {
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [isMediaAllow]);

  useEffect(() => {
    // Check if the wakeLock API is supported
    if (screenLock !== null) {
      screenLock();
      // The wakeLock API is supported, and you can use screenLock as needed
      // For example, you can acquire or release the screen wake lock based on user interactions.
    } else {
      // The wakeLock API is not supported in this browser
      // You might want to provide a message or fallback behavior for unsupported browsers.
       // console.log("Screen Wake Lock API is not supported in this browser.");
    }
  }, []);

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    const message =
      "Are you sure you want to leave? All provided data will be lost.";
    e.returnValue = message;
    return message;
  };

  let callRegister = null;
  let callInviter = null;

  /**
   * This function work as a setter function for state based
   * @param {string} type
   * @param {anyType} payload
   */
  const dispatch = (type, payload) => {
    webRTCDispatch({ type: type, payload: payload });
  };

  /**
   * This function prepare userAgent Option
   * @returns userAgentOption
   */
  const getUserAgentOption = () => {
    const localUri = `sip:${parentState.ParticipantUUID}@${domain}`;
    const uri = UserAgent.makeURI(localUri);
    const wssDomain = `wss://${domain}:${wssPort}/ws`;
    const transportOptions = {
      server: wssDomain,
    };
    const userAgentOptions = {
      authorizationPassword: parentState.ParticipantUUID,
      authorizationUsername: parentState.ParticipantUUID,
      transportOptions,
      uri,
      logBuiltinEnabled: logBuildInEnabled,
    };
    return userAgentOptions;
  };

  /**
   * This function will hide webRTC screen and show dial screen if there is any failures happen during connecting call
   */
  const showDialScreen = () => {
    dispatch("setCurrentWebRTCScreen", "callFailed");
    setTimeout(() => {
      hideWebRTCScreen();
    }, 3000);
  };

  /**
   * This function is responsible for tracking Record
   * @param {tracking msg} msg
   */
  const trackRecord = (msg) => {
    trackWebRTCRecords(parentState, "web_rtc_page", msg);
  };

  const connectionFailed = () => {
    trackRecord(WebRTCLogsMsg.connection_failed);
    showDialScreen();
  };

  let domain;
  let remoteUriPrefix;
  let wssPort;
  let token;
  let minConnectionSpeed;
  let logBuildInEnabled;
  let speedTestBaseUrl;
  let defaultSpeed; 
  let isSpeedTestEnabled;

  const beginCall = async () => {
    if (isMobileDevice()) {
      enterFullScreen();
    }
    await getWebRTCProps(parentState.ParticipantUUID)
      .then((res) => {
        const webRTCProps = res.data;
        domain = webRTCProps.domain
        remoteUriPrefix = webRTCProps.remoteUriPrefix
        wssPort = webRTCProps.wssPort
        speedTestBaseUrl = webRTCProps.speedTestBaseUrl
        defaultSpeed = webRTCProps.defaultSpeed
        isSpeedTestEnabled = webRTCProps.isSpeedTestEnabled
        minConnectionSpeed = webRTCProps.minConnectionSpeed;
        logBuildInEnabled = webRTCProps.logBuildInEnabled;
        token = webRTCProps.token;
      })
      .catch((err) => {
        console.log("error cath", err);
      });

    dispatch("setCurrentWebRTCScreen", "connectingCall");
    dispatch("setHideBottomBtn", true);
    trackRecord(WebRTCLogsMsg.checking_network_speed);
    checkNetworkSpeed(defaultSpeed, isSpeedTestEnabled, speedTestBaseUrl)
      .then((speed) => {
        const actualSpeed = parseFloat(speed);
        const osDetail = getOSDetails()
        trackRecord(`user device information ${osDetail}`);
        const browser = getBrowserDetails();
        trackRecord(
          `user's web browser is ${browser}`
        );
        trackRecord(`Network speed is ${speed}`);
        if (actualSpeed > minConnectionSpeed) {
          const registerHeaders = [`X-TKN: ${token}`];
          const registerOptions = { extraHeaders: registerHeaders };
            const userAgentOptions = getUserAgentOption();
            const userAgent = new UserAgent(userAgentOptions);
            callRegister = new Registerer(userAgent, registerOptions);
            /* creating user agent*/
            userAgent
              .start()
              .then(() => {
                trackRecord(WebRTCLogsMsg.user_created_successfully);
                callRegister.register();
              })
              .catch((err) => {
                trackRecord(WebRTCLogsMsg.user_created_failed);
                showDialScreen();
              });

            const registerEmitter = callRegister.stateChange;
            registerEmitter.on(function (state) {
              if (state === "Registered") {
                trackRecord(WebRTCLogsMsg.user_register_successfully);
                if(navigator.mediaDevices){
                  navigator.mediaDevices
                    .getUserMedia({ video: false, audio: true })
                    .then((mediaStream) => {
                      //  const videoTracks = mediaStream.getVideoTracks();
                      setIsMediaAllow(true);
                      const audioTracks = mediaStream.getAudioTracks();
                      trackRecord(
                        `${audioTracks[0]["label"]} is used for the call`
                      );
                      trackRecord(WebRTCLogsMsg.grant_media_access);
                      handleInvitation(userAgent);
                    })
                    .catch((error) => {
                      let errorMessage = WebRTCLogsMsg.denied_media_access;
                      if (error instanceof DOMException) {
                        errorMessage = error.message;
                      }
                      trackRecord(errorMessage);
                      dispatch("setShowMediaAccessModal", true);
                      dispatch("setCurrentWebRTCScreen", "");
                      dispatch("setHideBottomBtn", false);
                    });
                }
              } else {
                trackRecord(WebRTCLogsMsg.user_creation_fail);
                showDialScreen();
              }
            });
        } else {
          connectionFailed();
        }
      })
      .catch(() => {
        connectionFailed();
      });
  };

  /**
   * This Function will send Invitation
   * @param {Object} userAgent
   */
  const handleInvitation = (userAgent) => {
    const inviteHeaders = [`X-TKN: ${token}`];
    const remoteUri = `sip:${remoteUriPrefix}@${domain}`;
    const inviteOptions = {
      media: {
        constraints: { audio: true, video: false },
        render: { remote: document.getElementById("localVideo") },
      },
      extraHeaders: inviteHeaders,
    };

    callInviter = new Inviter(
      userAgent,
      UserAgent.makeURI(remoteUri),
      inviteOptions
    );

    dispatch("setCallInviterCopy", callInviter);
    callInviter
      .invite(remoteUri, inviteOptions)
      .then(() => {
        trackRecord(WebRTCLogsMsg.invitation_sent_successfully);
        dispatch("setCurrentWebRTCScreen", "callIsInProgress");
      })
      .catch((error) => {
        trackRecord(WebRTCLogsMsg.Invitation_failed);
        showDialScreen();
        // INVITE did not send
      });

    callInviter.stateChange.addListener((state) => {
      switch (state) {
        case SessionState.Initial:
          break;
        case SessionState.Establishing:
          break;
        case SessionState.Established:
          let mediaElement = document.getElementById("remoteVideo");
          let remoteStream = new MediaStream();
          callInviter.sessionDescriptionHandler.peerConnection
            .getReceivers()
            .forEach((receiver) => {
              if (receiver.track) {
                remoteStream.addTrack(receiver.track);
              }
            });
          mediaElement.srcObject = remoteStream;
          const playPromise = mediaElement.play();
          playPromise
            .then((res) => {
              console.log("res", res);
            })
            .catch((err) => {
              connectionFailed();
            });
          break;
        case SessionState.Terminating:
          break;
        case SessionState.Terminated:
          terminateCall();
          handelContinue(9, "Access");
          break;
        default:
          throw new Error("Unknown Session State.");
      }
    });
  };

  /**This function will terminate the call */
  const terminateCall = (props) => {
    // setShowModal(false);
    if (!callInviterCopy?.state) {
      return;
    }
    switch (callInviterCopy.state) {
      case SessionState.Initial:
      case SessionState.Establishing:
        try {
          callInviterCopy.cancel();
          setIsMediaAllow(false);
          trackRecord(WebRTCLogsMsg.call_terminate_successfully);
        } catch (error) {
          callInviterCopy.reject();
        }
        break;
      case SessionState.Established:
        callInviterCopy.bye();
        break;
      case SessionState.Terminating:
      case SessionState.Terminated:
      default:
        break;
    }
    setTimeout(() => {
      dispatch("setShowHangUpModal", false);
      exitFullScreen();
    }, 500);
    callInviter = null;
    document.getElementById("remoteVideo").srcObject = null;
    document.getElementById("localVideo").srcObject = null;
  };

  const getUpdatedScreen = () => {
    /* This code will show connecting call screen*/
    const { currentWebRTCScreen } = webRTCState;

    if (currentWebRTCScreen === "connectingCall") {
      return (
        <>
          {webRTCPageContent.setting_up_connection_btn}{" "}
          <div>
            <img src={greenPhoneIcon} alt="Phone Icon" />
          </div>
        </>
      );
    }

    /* This code will show inProgress screen*/
    if (currentWebRTCScreen === "callIsInProgress") {
      return (
        <>
          {webRTCPageContent.call_progress_btn}
          <div onClick={() => dispatch("setShowHangUpModal", true)}>
            <img src={inCallIcon} alt="Phone Icon" />
          </div>
        </>
      );
    }

    /* This code will show failed screen*/
    if (currentWebRTCScreen === "callFailed") {
      return (
        <>
          {webRTCPageContent.failed_connection_btn}{" "}
          <div>
            <img src={redPhoneIcon} alt="Phone Icon" />
          </div>
        </>
      );
    }

    /* This code will show start questionnaire screen*/
    return (
      <>
        {webRTCPageContent.start_questionnaire_btn}
        <div onClick={() => beginCall()}>
          <img src={bluePhoneIcon} alt="Phone Icon" />
        </div>
      </>
    );
  };

  return (
    <>
      <Row>
        <Col md={12} className="">
          <div className="webRtc-container">
            <section>
              <div className="questionnaire_container">
                {getUpdatedScreen()}
              </div>
            </section>
          </div>
          <div>
            <video
              id="localVideo"
              width="1%"
              height="1%"
              autoPlay
              playsInline
            />
            <video
              id="remoteVideo"
              width="1%"
              height="1%"
              autoPlay
              playsInline
            />{" "}
          </div>

          {showMediaAccessModal && (
            <CustomModal
              isVisible={showMediaAccessModal}
              onClose={() => dispatch("setShowMediaAccessModal", false)}
              title={webRTCPageContent.media_access_header_message}
              content={webRTCPageContent.media_access_body_message}
              closeLabel={webRTCPageContent.close_button}
              dialogClassName="custom-dialog"
              HeaderClassName="custom-modal-header"
              BodyClassName="custom-modal-body-container"
              closeButtonVariant="secondary"
              closeButtonClassName="custom-modal-close-btn"
              modalClassName="custom-modal-container"
            />
          )}

          {showHangUpModal && (
            <CustomModal
              isVisible={showHangUpModal}
              onClose={() => dispatch("setShowHangUpModal", false)}
              title={webRTCPageContent.warning_message}
              content={webRTCPageContent.hangup_confirmation_message}
              submitLabel={webRTCPageContent.terminate_button}
              closeLabel={webRTCPageContent.close_button}
              onSubmit={terminateCall}
              dialogClassName="custom-dialog"
              HeaderClassName="custom-modal-header"
              BodyClassName="custom-modal-body-container"
              submitButtonVariant="primary"
              submitButtonClassName="custom-modal-submit-btn"
              closeButtonClassName="custom-modal-close-btn"
              modalClassName="custom-modal-container"
            />
          )}
        </Col>
      </Row>
      <div className="full-widthbutton-sec">
        <>
          {!hideBottomBtn && (
            <>
              <Button
                variant="primary"
                type="submit"
                className="mr-4 dial_back"
                onClick={() =>
                  handelBack(
                    findPreviousEnabledPageNumber(pageTypesWithEnabledFlag, 8),
                    "Access"
                  )
                }
              >
                {parentState.accessData.button_reverse_text}
              </Button>
            </>
          )}
        </>
      </div>
    </>
  );
};

export default MainWebRTC;