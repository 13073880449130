import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function CustomModal({
  isVisible,
  onClose,
  title,
  content,
  closeLabel,
  submitLabel,
  onSubmit,
  dialogClassName = "",
  HeaderClassName = "",
  BodyClassName = "",
  submitButtonVariant,
  closeButtonVariant,
  submitButtonClassName = "",
  closeButtonClassName = "",
  modalClassName, // New prop for modal container class
}) {
  return (
    <Modal
      show={isVisible}
      onHide={onClose}
      className={modalClassName || "custom-modal"} // Use modalClassName or default class
      dialogClassName={dialogClassName}
    >
      {title && (
        <Modal.Header closeButton className={HeaderClassName}>
          {title}
        </Modal.Header>
      )}
      {content && <Modal.Body className={BodyClassName}>{content}</Modal.Body>}
      <Modal.Footer>
        {closeLabel && (
          <Button
            variant={closeButtonVariant || "secondary"}
            onClick={onClose}
            className={closeButtonClassName}
          >
            {closeLabel}
          </Button>
        )}
        {submitLabel && (
          <Button
            variant={submitButtonVariant || "primary"}
            onClick={onSubmit}
            className={submitButtonClassName}
          >
            {submitLabel}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default CustomModal;
