import { useState, useEffect } from "react";
import useScreenOrientationLock from "./useScreenOrientationLock";

// Custom hook for handling fullscreen mode
function useFullScreen() {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const { lockOrientation, unlockOrientation } = useScreenOrientationLock();

  const enterFullScreen = () => {
    const element = document.documentElement; // Get the root HTML element
    console.log("element", element);
    if (element.requestFullscreen) {
      element
        .requestFullscreen()
        .then(() => {
          lockOrientation()
        })
        .catch((error) => {
          console.error("Error entering fullscreen mode:", error);
          unlockOrientation()
        });
    } else {
      console.warn("Fullscreen API is not available in this browser");
    }
  };

  const exitFullScreen = () => {
    if (document.exitFullscreen) {
      document
        .exitFullscreen()
        .then(() => {
          setIsFullScreen(false);
          unlockOrientation()
          console.log("Exited fullscreen mode");
        })
        .catch((error) => {
          unlockOrientation()
          console.error("Error exiting fullscreen mode:", error);
        });
    } else {
      console.warn("Fullscreen API is not available in this browser");
    }
  };

  useEffect(() => {
    const fullscreenChangeHandler = () => {
      setIsFullScreen(document.fullscreenElement !== null);
    };

    document.addEventListener("fullscreenchange", fullscreenChangeHandler);

    return () => {
      document.removeEventListener("fullscreenchange", fullscreenChangeHandler);
    };
  }, []);

  return { isFullScreen, enterFullScreen, exitFullScreen };
}

export default useFullScreen;
